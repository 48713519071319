import { createIcon } from '@chakra-ui/react' // https://v2.chakra-ui.com/docs/components/icon#using-a-third-party-icon-library

export const IconCellular = createIcon({
  displayName: 'IconCellular',
  viewBox: '0 0 12 10',
  path: (
    <g strokeLinecap="round" strokeWidth="2">
      <path
        className="right"
        d="M10.666 1v8M7.444 3.354v5.647M4.222 6.295v2.706"
      />
      <path className="left" d="M1 8.059V9" />
    </g>
  ),
})

export const IconConnectionQuality = createIcon({
  displayName: 'IconConnectionQuality',
  viewBox: '0 0 9 9',
  path: [
    <path d="M0 6H2V9H0V6Z" fill="#28994E" />,        // eslint-disable-line
    <path d="M3.5 3H5.5V9H3.5V3Z" fill="#28994E" />,  // eslint-disable-line
    <path d="M7 0H9V9H7V0Z" fill="#28994E" />,        // eslint-disable-line
  ],
})

export const IconFilterOutlineMod = createIcon({
  displayName: 'IconFilterOutlineMod',
  viewBox: '0 0 24 13',
  path: (
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.563"
      d="M.816 1.031h21.875M1.013 6.5h14.063M1.012 11.969H5.7"
    />
  ),
})

export const IconLineSingleHorizontal = createIcon({
  displayName: 'IconLineSingleHorizontal',
  viewBox: '0 0 26 26',
  path: (
    <rect
      width="3"
      height="10"
      x="8"
      y="15"
      fill="currentColor"
      rx="1.5"
      transform="rotate(-90 8 15)"
    />
  ),
})

export const IconLineDoubleHorizontal = createIcon({
  displayName: 'IconLineDoubleHorizontal',
  viewBox: '0 0 26 26',
  path: [
    // eslint-disable-next-line react/jsx-key
    <rect
      width="3"
      height="10"
      x="8"
      y="12"
      fill="currentColor"
      rx="1.5"
      transform="rotate(-90 8 12)"
    />,
    // eslint-disable-next-line react/jsx-key
    <rect
      width="3"
      height="10"
      x="8"
      y="17"
      fill="currentColor"
      rx="1.5"
      transform="rotate(-90 8 17)"
    />,
  ],
})

export const IconLineTripleHorizontal = createIcon({
  displayName: 'IconLineTripleHorizontal',
  viewBox: '0 0 26 26',
  path: [
    // eslint-disable-next-line react/jsx-key
    <rect
      width="3"
      height="10"
      x="8"
      y="14"
      fill="currentColor"
      rx="1.5"
      transform="rotate(-90 8 14)"
    />,
    // eslint-disable-next-line react/jsx-key
    <rect
      width="3"
      height="10"
      x="8"
      y="9"
      fill="currentColor"
      rx="1.5"
      transform="rotate(-90 8 9)"
    />,
    // eslint-disable-next-line react/jsx-key
    <rect
      width="3"
      height="10"
      x="8"
      y="19"
      fill="currentColor"
      rx="1.5"
      transform="rotate(-90 8 19)"
    />,
  ],
})

export const IconRecording = createIcon({
  displayName: 'IconRecording',
  viewBox: '0 0 12 12',
  path: <circle cx="6" cy="6" r="6" fill="currentColor" />,
})

export {
  IoAdd,
  IoAddOutline,
  IoArrowDownSharp,
  IoArrowUpSharp,
  IoBookOutline,
  IoCall,
  IoCheckboxOutline,
  IoCheckmark,
  IoCheckmarkCircle,
  IoCheckmarkCircleOutline,
  IoChevronBack,
  IoChevronBackCircle,
  IoChevronDown,
  IoChevronForward,
  IoClose,
  IoCloseCircleOutline,
  IoContrast,
  IoContrastOutline,
  IoDocumentTextOutline,
  IoDownloadOutline,
  IoEllipsisHorizontal,
  IoEllipsisVertical,
  IoExpand,
  IoHelpCircleOutline,
  IoLockClosed,
  IoLockOpen,
  IoLogOutOutline,
  IoMic,
  IoMicOff,
  IoPerson,
  IoPlayCircle,
  IoPauseCircle,
  IoPauseCircleOutline,
  IoPower,
  IoRecordingSharp,
  IoSearch,
  IoShare,
  IoTimerOutline,
  IoVideocam,
  IoVideocamOff,
  IoVolumeMute,
} from 'react-icons/io5' // https://react-icons.github.io/react-icons/icons/io5/

export { MdLiveTv } from 'react-icons/md'
export { TbSum } from 'react-icons/tb'
