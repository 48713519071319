import { useEffect } from 'react'

import {
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Select,
  Spacer,
  Text,
  Tooltip,
  chakra,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import type { InterviewByName } from 'types/graphql'
import create from 'zustand'

import { Link as RWLink, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import AppLogo from 'src/components/AppLogo/AppLogo'
import { ColorModeSwitch } from 'src/components/ColorModeSwitch'
import {
  IoPauseCircle,
  IoPauseCircleOutline,
  MdLiveTv,
} from 'src/components/Icons'
import Profile from 'src/components/Profile/Profile'
import { colorFilters } from 'src/theme/foundations/colors'
import { useIsClientMobile } from 'src/utils/hooks/useClientBreakpoints'
import useI18n from 'src/utils/hooks/useI18n'
import { useMobileLandscape } from 'src/utils/hooks/useMobileLandscape'

interface HostLayoutStore {
  title: InterviewByName['title']
  isHostInRoom: InterviewByName['isHostInRoom']
}

export const useHostLayoutStore = create<HostLayoutStore>()(() => ({
  // `null` is a kind of third state that is used to figure out when not to render value related components.
  title: null,
  isHostInRoom: null,
}))

type HostLayoutProps = {
  /**
   * If `true`, the main content will be fixed (no scrollbars) & fill the available viewport.
   * Also, the link on the application logo will be disabled to prevent exiting the session.
   *
   * Default: `false`
   */
  inSession?: boolean
  inRecordings?: boolean
  children?: React.ReactNode
}

const AppContainer = ({ isMaximized = false, hasMargin = true, ...props }) =>
  !isMaximized ? (
    <Container maxW="container.lg" px={[5, 10]} {...props} />
  ) : (
    <Container
      px={0}
      maxW="full"
      display="flex"
      overflow="visible"
      flexDirection="column"
      h={['calc(100vh - 3rem)', 'calc(100vh - 4.75rem)']}
      {...props}
      {...(!hasMargin && { m: 0 })}
    />
  )

const GET_PUBLICINFO = gql`
  query PublicInfoQuery {
    publicInfo: publicInfo {
      version
    }
  }
`

const LogoWrapper = ({ allowNavigation, children }) =>
  allowNavigation ? (
    <Link as={RWLink} to={routes.home()} fontSize={['.375rem', '.5rem']}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )

const HostLayout = ({
  inSession = false,
  inRecordings = false,
  children,
}: HostLayoutProps) => {
  const { t, i18n } = useI18n()
  const isMobile = useIsClientMobile()
  const { isAuthenticated, currentUser, logOut, client } = useAuth()
  const svgFilter = useColorModeValue(colorFilters.blue, colorFilters.white)

  const title = useHostLayoutStore((state) => state.title)
  const tooltipColor = useColorModeValue('ivtBlue', 'lightGrey')
  const isHostInRoom = useHostLayoutStore((state) => state.isHostInRoom)
  const PauseIcon = useColorModeValue(IoPauseCircle, IoPauseCircleOutline)

  const isMobileLandscape = useMobileLandscape()
  const isMobileLandscapeInSession = inSession && isMobileLandscape

  const { isOpen: isTooltipOpen, onToggle: toggleTooltip } = useDisclosure()

  const changeLanguageEventHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => i18n.changeLanguage(e.target.value)

  const { data } = useQuery(GET_PUBLICINFO, {
    fetchPolicy: 'cache-and-network', // Doesn't check cache before making a network request
  })
  const version = data?.publicInfo?.version

  // `isAuthenticated`, `client.getActiveAccount()` and similar values are NOT
  // populated before the events "msal:handleRedirectEnd" and
  // "msal:loginSuccess". Hence we need to check if `isAuthenticated` changed
  // instead of using `client.addEventCallback()`
  useEffect(() => {
    if (!isAuthenticated) return

    const refreshToken = () =>
      // Not supported in `const { getToken } = useAuth()`
      // We do not want a `aquireTokenRedirect` here like in redwood/packages/auth-providers/azureActiveDirectory/web/src/azureActiveDirectory.ts
      // because that would not be appropriate in a recorded interview session
      // TODO: try-catch with timeout() to execute again after a short delay
      client.acquireTokenSilent({
        account: client.getActiveAccount(),
        scopes: ['openid', 'profile'],
        forceRefresh: true, // Avoid using cached token
      })

    // TODO: Move constant of seconds to configuration file
    // Refresh token every 55 minutes
    const intervalId = setInterval(refreshToken, 55 * 60 * 1000)

    // Debugging
    const callbackId = client.addEventCallback((event) => {
      console.debug('Azure AD event:', event)
      console.debug(client.getActiveAccount())
    })

    // Clean up when component unmounts or authentication status changes
    return () => {
      clearInterval(intervalId)
      client.removeEventCallback(callbackId)
    }
  }, [client, isAuthenticated])

  return (
    <>
      <chakra.header
        w="full"
        overflowY="hidden"
        borderBottomWidth={1}
        h={!isMobileLandscapeInSession ? ['3rem', '4.75rem'] : '3rem'}
        borderColor="ivtText"
      >
        <AppContainer
          as={Flex}
          h="full"
          justify="space-between"
          {...(isMobileLandscapeInSession && { px: [2, 5] })}
        >
          <Flex align="center">
            <LogoWrapper allowNavigation={!inSession}>
              <AppLogo isSmall={isMobileLandscapeInSession} />
            </LogoWrapper>
          </Flex>
          {isMobileLandscapeInSession && title && (
            <Tooltip
              label={
                isHostInRoom
                  ? t('waitingRoom.interviewStartedShort')
                  : t('waitingRoom.interviewNotStartedShort')
              }
              hasArrow
              bgColor={tooltipColor}
              placement="bottom"
              isOpen={isTooltipOpen}
              display="flex"
              alignItems="center"
            >
              <HStack
                alignItems="center"
                spacing="4"
                {...(isHostInRoom !== null && { onClick: toggleTooltip })}
              >
                <Heading fontWeight="medium" fontSize="md">
                  {title}
                </Heading>

                {isHostInRoom !== null && (
                  <Icon
                    color={isHostInRoom ? 'green.200' : 'orange.200'}
                    as={isHostInRoom ? MdLiveTv : PauseIcon}
                    boxSize={6}
                  />
                )}
              </HStack>
            </Tooltip>
          )}
          <HStack justify="flex-end" spacing={0}>
            {((!isMobile && !isMobileLandscapeInSession) ||
              !isAuthenticated) && <ColorModeSwitch ml={4} />}
            <Select
              variant="outline"
              onChange={changeLanguageEventHandler}
              size="sm"
              value={i18n.resolvedLanguage}
              w="auto"
              minW="16"
              borderRadius="lg"
              borderColor="transparent"
            >
              <option value="en">EN</option>
              <option value="de">DE</option>
            </Select>
            {isAuthenticated && (
              <>
                <Spacer width={1.5} />
                <Profile
                  userDisplayName={currentUser.name}
                  onLogoutClick={logOut}
                  logoutText={t('HostLayout.logOut')}
                  faqText={t('HostLayout.faq')}
                  manualText={t('HostLayout.manual')}
                  releaseNotesText={t('HostLayout.releaseNotes')}
                  showColorModeToggle={isMobile || isMobileLandscapeInSession}
                  isSmall={isMobileLandscapeInSession}
                />
                {!isMobileLandscapeInSession && (
                  // https://stackoverflow.com/questions/22252472/how-can-i-change-the-color-of-an-svg-element/53336754#53336754
                  <Image
                    src="/img/logo.svg"
                    alt="Logo"
                    height="2.2rem"
                    role="img"
                    style={{ marginLeft: '1.3rem', marginRight: '-.675rem' }}
                    filter={svgFilter}
                  />
                )}
              </>
            )}
          </HStack>
        </AppContainer>
      </chakra.header>

      <AppContainer
        as={chakra.main}
        mt={!inRecordings ? (!isMobileLandscapeInSession ? 16 : 5) : 2}
        isMaximized={inSession}
        hasMargin={isMobileLandscapeInSession}
      >
        {children}
      </AppContainer>

      <AppContainer
        as={chakra.footer}
        isMaximized={false}
        userSelect="none"
        h={6}
        marginInline="unset"
        paddingInline="6px"
        {...(inSession && {
          position: 'absolute',
          bottom: 0,
          h: '2.375rem',
          zIndex: '-1',
        })}
      >
        <Text fontSize={'sm'} color={'midBlue'}>
          {version && `v${version}`}
        </Text>
      </AppContainer>
    </>
  )
}

export default HostLayout
